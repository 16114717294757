import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div><Link to="/titlePagePage">Title Page</Link></div>
    <div><Link to="/benchmarkLineChartPage">Benchmark Line Chart</Link></div>
    <div><Link to="/horizontalBarChartPage">Horizontal Bar Chart</Link></div>
    <div><Link to="/complianceTrendsPage">Compliance Trends</Link></div>
    <div><Link to="/conversionRateDoctorPage">Conversion Rate by Doctor</Link></div>
      <div><Link to="/conversionRateProcedurePage">Conversion Rate by Procedure</Link></div>
  </Layout>
)

export default IndexPage
